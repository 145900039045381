export default [
  {
    name: 'omni-storeviews',
    path: 'omni/storeviews',
    component: () => import(/* webpackChunkName: "omni-storeviews" */ '@/view/omni/storeviews/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-storeviews-list',
        component: () => import(/* webpackChunkName: "omni-storeviews" */ '@/view/omni/storeviews/TableList.vue'),
      },
    ],
  },
  {
    name: 'omni-clients',
    path: 'omni/clients',
    component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-clients-list',
        component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/TableList.vue'),
      },
      {
        path: ':id',
        name: 'omni-client-details',
        component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Index.vue'),
        children: [
          {
            path: 'information',
            name: 'omni-client-information',
            component: () =>
              import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Information.vue'),
          },
          {
            path: 'locations',
            name: 'omni-client-locations',
            component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Locations.vue'),
          },
          {
            path: 'users',
            name: 'omni-client-users',
            component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Users.vue'),
          },
          {
            path: 'attributes',
            name: 'omni-client-attributess',
            component: () =>
              import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Attributes.vue'),
          },
          {
            path: 'attributesGroup',
            name: 'omni-client-attributess-group',
            component: () =>
              import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/AttributeGroup.vue'),
          },
          {
            path: 'resources',
            name: 'omni-client-resources',
            component: () => import(/* webpackChunkName: "omni-clients" */ '@/view/omni/clients/details/Resources.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'omni-user-logs',
    path: 'omni/user-logs',
    component: () => import(/* webpackChunkName: "omni-user-logs" */ '@/view/omni/userLogs/Index.vue'),
  },
  {
    name: 'omni-user-logs',
    path: 'omni/reporting',
    component: () => import(/* webpackChunkName: "omni-user-logs" */ '@/view/omni/report/Index.vue'),
  },
];
