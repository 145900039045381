import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import omniAdminRoutes from './OmniAdminRoutes';
import { ACCESS_TOKEN } from '@/config/cookieKeys';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: true },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: false },
  },
  {
    name: 'OmniAdmin',
    path: '/admin/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...omniAdminRoutes],
    meta: { auth: true, admin: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { $cookies, $store } = router.app.config.globalProperties;
  if ($cookies.get(ACCESS_TOKEN)) {
    if (!$store.state.auth.isAuthenticated) {
      await $store.dispatch('getMe');
      next();
    }
    if (!to.meta.auth) next({ to: '/' });
    else {
      if (
        from.name === 'omni-product-details' ||
        from.name === 'omni-national-codes' ||
        from.name === 'omni-media-upload'
      ) {
        if (!$store.state.omni.isChanged) {
          next();
        } else {
          $store.state.omni.showModal = true;
          $store.state.omni.nextRoute = to.name;
        }
      } else next();
    }
  } else {
    if (to.meta.auth) next({ name: 'login' });
    else next();
  }
});

export default router;
