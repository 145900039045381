const intialStoreView = {
  id: -1,
  code: '',
  store_id: '',
  company_id: null,
  payment_provider: null,
  api_key_1: null,
  api_key_2: null,
  payment_additional_1: null,
  payment_additional_2: null,
  payment_additional_3: null,
  es_url: null,
  es_index: null,
  es_username: null,
  es_password: null,
  vsf_url: null,
  vsf_preview: null,
  website_id: '',
  webhook_token: '',
  language: '',
  currency: '',
  currency_code: '',
  paypal: {
    id: -1,
    client_id: '',
    client_secret: '',
    public_key: '',
    client_id_sandbox: '',
    client_secret_sandbox: '',
    public_key_sandbox: '',
    status: false,
    manual_capture: false,
    refund_in_platform: false,
    sandbox: false,
  },
  stripe: {
    id: -1,
    public_api_key: '',
    secret_api_key: '',
    webhook_secret: '',
    public_api_key_sandbox: '',
    secret_api_key_sandbox: '',
    webhook_secret_sandbox: '',
    status: false,
    manual_capture: false,
    refund_in_platform: false,
    sandbox: false,
  },
  cybersource: {
    id: -1,
    merchant_id: '',
    key: '',
    shared_secret_key: '',
    merchant_id_sandbox: '',
    key_sandbox: '',
    shared_secret_key_sandbox: '',
    status: false,
    manual_capture: false,
    refund_in_platform: false,
    sandbox: false,
  },
  checkoutcom: {
    id: -1,
    public_api_key: '',
    secret_api_key: '',
    webhook_secret: '',
    public_api_key_sandbox: '',
    secret_api_key_sandbox: '',
    webhook_secret_sandbox: '',
    status: false,
    manual_capture: false,
    refund_in_platform: false,
    sandbox: false,
  },
};

export default {
  initStoreViews(state) {
    state.storeview = intialStoreView;
  },
  fetchStoreviewsBegin(state) {
    state.loading = true;
  },
  fetchStoreviewsSuccess(state, items) {
    state.loading = false;
    state.storeviews = items.reduce((result, item) => {
      let { company, ...rest } = item;
      if (company) {
        rest['company_id'] = company.id;
      }

      return [...result, rest];
    }, []);
  },
  storeviewsSavingBegin(state) {
    state.saving = true;
  },
  setError(state, err) {
    state.loading = false;
    if (err) state.error = 'There was an error processing this request';
    state.saving = false;
  },
  setStoreview(state, payload) {
    state.storeview = payload;
  },
  toggleStoreviewEditModal(state, payload) {
    state.visibleEditModal = payload;
  },
  setClientName(state, name) {
    state.filterData['name'] = name;
  },
};
