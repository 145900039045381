export default {
  fetchCouponsBegin(state) {
    state.loading = true;
  },
  fetchCouponsDataSuccess(state, { items, total_count }) {
    state.coupons = items;
    state.loading = false;
    state.saving = false;
    state.pagination.total_count = total_count;
  },
  fetchSalesRuleDataSuccess(state, data) {
    state.salesRule = data;
    state.loading = false;
  },
  updatePagination(state, { key, value }) {
    state.pagination[key] = value;
  },
  setError(state, err) {
    if (err) state.error = 'There was an error processing this request';
    state.loading = false;
    state.saving = false;
  },
  setShowMarketingModal(state, value) {
    state.visible = value;
  },
};
