import mutations from './mutations';
import getters from './getters';
import { DataService } from '@/config/dataService/dataService';
import { Notification } from 'ant-design-vue';
import router from '@/routes/protectedRoute';
import axios from 'axios';

const initialFilters = {
  final_price: {
    from: '',
    to: '',
  },
  quantity: {
    from: '',
    to: '',
  },
  name: '',
  sku: '',
  status: null,
  product_group: [],
};
// const filterTerms = {
//   name: 'wildcard',
//   final_price: 'range',
//   sku: 'wildcard',
//   visibility: 'term',
//   status: 'term',
// };

const state = () => ({
  websites: [],
  product: {},
  products: [],
  childProducts: [],
  childProduct: {},
  allNationalCodes: [],
  loading: false,
  childProductLoading: false,
  error: null,
  totalCount: 0,
  pageSize: 25,
  currentPage: 1,
  filters: {},
  attributeOptions: [],
  saving: false,
  attributes: [],
  filterData: {},
  filterData1: {},
  isShow: false,
  isChanged: false,
  showModal: false,
  nextRoute: '',
  tyre: {
    width: '',
    rim: '',
    profile: '',
  },
  widths: [],
  rims: [],
  profiles: [],
  national_code: '',
  stateChanged: 0,
  productFilterVisible: false,
});

const baseUrl = 'https://www.alfardancommercial.com/api/ext/alfardan/tire-size/options';

const actions = {
  async setInitFilters({ commit }) {
    commit('setProductFilters', initialFilters);
    commit('setFiltersData', {});
  },
  async fetchWidths({ commit }) {
    try {
      const { data } = await axios.post(`${baseUrl}/width`);
      commit('setWidths', data.result);
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchRims({ commit }, payload) {
    try {
      const { data } = await axios.post(`${baseUrl}/rim`, payload);
      commit('setRims', data.result);
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchProfiles({ commit }, payload) {
    try {
      const { data } = await axios.post(`${baseUrl}/profile`, payload);
      commit('setProfiles', data.result);
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchNationalCodes({ commit }, payload) {
    try {
      const { data } = await axios.post(
        `https://www.alfardancommercial.com/api/ext/alfardan/tire-size/national-code`,
        payload,
      );
      commit('setNationalCode', data.result);
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchProducts({ commit, state, rootState }) {
    commit('fetchBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let query = {
        pageSize: state.pageSize,
        currentPage: state.currentPage,
      };
      console.log(state.filters, 'filters');
      if (Object.keys(state.filters).length) {
        query.filter = Object.keys(state.filters).reduce((result, key) => {
          if (key === 'sku') return result;
          let subQuery = null;
          if (state.filters[key].contain_filter) {
            if (state.filters[key].value) {
              subQuery = `*${state.filters[key].value}*`;
              result = [
                ...result,
                {
                  wildcard: {
                    [key]: subQuery,
                  },
                },
              ];
            }
          }
          if (state.filters[key].equal_filter) {
            if (state.filters[key].value !== '') {
              subQuery = state.filters[key].value;
              result = [
                ...result,
                {
                  term: {
                    [key]: subQuery,
                  },
                },
              ];
            }
          }

          return result;
        }, []);

        query.must_not = Object.keys(state.filters).reduce((result, key) => {
          if (state.filters[key].blank_filter) {
            result = [
              ...result,
              {
                exists: {
                  field: key,
                },
              },
            ];
          }

          return result;
        }, []);

        query.must_not = JSON.stringify(query.must_not);

        query.filter = JSON.stringify(query.filter);

        if (state.filters?.sku?.value) {
          query.should = JSON.stringify([
            {
              wildcard: { sku: `*${state.filters.sku.value}*` },
            },
            {
              wildcard: { 'configurable_children.sku': `*${state.filters.sku.value}*` },
            },
          ]);
        }
      }

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products/elasticsearch`, query);
      if (status === 'success') {
        commit('fetchProductsSuccess', data);
      }
    } catch (err) {
      commit('setError', err);
    }
    commit('setStateChanged', 0);
  },

  async fetchProduct({ commit, rootState }, { sku, disableLoading }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      if (!disableLoading) commit('fetchBegin');
      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products/${sku}?storeId=${activeStoreView.store_id}`);
      if (status === 'success') {
        commit('setProduct', data);
        commit('setNationalCodeFromProduct');
        const {
          data: { data: attributes, status },
        } = await DataService.get(
          `${activeStoreView.code}/products/attribute-sets/${data.attribute_set_id}/attributes`,
        );

        if (status === 'success') {
          commit('setAttributes', attributes);
        }

        if (data.type_id === 'configurable') {
          const childIds = data?.extension_attributes?.configurable_product_links;
          if (childIds) {
            const searchCriteria = {
              filterGroups: [
                {
                  filters: [
                    {
                      field: 'entity_id',
                      value: childIds.join(','),
                      condition_type: 'in',
                    },
                  ],
                },
              ],
            };

            const {
              data: { data, status },
            } = await DataService.get(`${activeStoreView.code}/products`, {
              searchCriteria,
            });

            if (status === 'success') {
              commit('setChildProducts', data.items);
            }
          }
        }
      }
      if (!disableLoading) commit('fetchEnd');
    } catch (err) {
      commit('setError', err);
    }
  },

  async updateProductMedia({ commit, state, rootState, dispatch }, { files }) {
    const activeStoreView = rootState.auth.activeStoreView;
    commit('productSavingBegin');
    try {
      if (state.stateChanged) {
        dispatch('updateProduct', {
          sku: state.product.sku,
          data: {
            sku: state.product.sku,
            status: state.stateChanged,
          },
        });
      }
      await DataService.post(`${activeStoreView.code}/products/${state.product.sku}/media`, {
        files,
      });
      const pResponse = await DataService.get(`${activeStoreView.code}/products/${state.product.sku}`);
      commit('setProduct', pResponse.data.data);

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/products/${state.product.sku}`);
      if (status === 'success') {
        commit('setProduct', data);
      }
      commit('productSavingEnd');

      Notification['success']({
        message: 'Update product media success',
        description: 'The media of this product was updated successfully.',
      });
    } catch (err) {
      commit('setError', err);
    }
  },

  async updateProduct({ commit, state, rootState, dispatch }, payload = {}) {
    commit('productSavingBegin');
    commit('fetchBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let response = null;
      if (!payload.data) {
        response = await DataService.put(`${activeStoreView.code}/products/${state.product.sku}`, state.product);
      } else {
        if (state.stateChanged) {
          console.log(payload.status, 'status', state.stateChanged);
          payload.data.status = state.stateChanged;
        }
        response = await DataService.put(`${activeStoreView.code}/products/${payload.sku}`, payload.data);
      }
      if (response.data.status === 'success') {
        if (!payload.data) commit('setProduct', response.data.data);
        await dispatch('fetchProduct', {
          sku: state.product.sku,
          disableLoading: true,
        });
        commit('productSavingEnd');
        commit('fetchEnd');
        Notification['success']({
          message: 'Update success',
          description: 'The product was updated successfully.',
        });
      }
    } catch (err) {
      commit('setError', err);
    }
  },

  async websites({ commit }) {
    commit('fetchBegin');
    try {
      // const activeStoreView = rootState.auth.activeStoreView;
      const response = await DataService.get(`/websites`);

      if (response.data.status === 'success') {
        commit('setWebsites', response.data.data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },

  async createProduct({ commit, state, rootState }, { data = null }) {
    commit('productSavingBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let response = null;
      if (data) {
        response = await DataService.post(`${activeStoreView.code}/products`, { product: data });
      } else {
        response = await DataService.post(`${activeStoreView.code}/products`, { product: state.product });
      }
      if (response.data.status === 'success') {
        router.push({
          name: 'omni-product-details',
          params: {
            sku: response.data.data.sku,
          },
        });
        commit('productSavingEnd');
        Notification['success']({
          message: 'Creating product success',
          description: 'The product was created successfully.',
        });
      }
    } catch (err) {
      commit('setError', err);
    }
  },

  async deleteProduct({ commit, rootState, state, dispatch }, { sku, forceRouteChange = false }) {
    commit('childProductLoadingBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      const {
        data: { status },
      } = await DataService.delete(`${activeStoreView.code}/products/${sku}`);
      if (status === 'success') {
        await dispatch('fetchProduct', {
          sku: state.product.sku,
          disableLoading: true,
        });
        commit('childProductLoadingEnd');
        Notification['success']({
          message: 'The product was deleted successfully.',
        });
        if (!forceRouteChange) {
          router.push({
            name: 'omni-products',
          });
        }
      }
    } catch (err) {
      commit('setError', err);
      commit('childProductLoadingEnd');
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
