import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const formatDate = (value) => {
  const date = new Date(value);
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};

const state = () => ({
  formState: {
    clientId: 0,
    storeview: {},
    startDate: '',
    endDate: '',
  },
  reportData: {},
  error: null,
  storeviews: [],
  reportingData: 0,
  orderNumbers: 0,
  enquiryNumbers: 0,
});

const actions = {
  async fetchReportData({ commit, state }) {
    try {
      let must = [];
      if (state.formState.clientId) {
        must.push({
          term: {
            ClientID: state.formState.clientId,
          },
        });
      }

      if (state?.formState?.storeview?.id) {
        must.push({
          term: {
            StoreID: state.formState.storeview.id,
          },
        });
      }

      let dateRange = {};
      if (state.formState.startDate) {
        dateRange['gte'] = formatDate(state.formState.startDate);
      } else {
        const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        dateRange['gte'] = formatDate(sevenDaysAgo.toString());
      }

      if (state.formState.endDate) {
        dateRange['lte'] = formatDate(state.formState.endDate);
      }

      must.push({
        range: {
          Date: dateRange,
        },
      });

      const payload = {
        query: {
          bool: {
            must,
          },
        },
        aggs: {
          groupByMake: {
            terms: {
              field: 'Make',
            },
            aggs: {
              groupByModel: {
                terms: {
                  field: 'Model',
                },
              },
            },
          },
        },
      };

      const { data } = await DataService.get(`report`, { payload });
      commit('setReportData', data.data);
    } catch (err) {
      commit('setReportError', err);
    }
  },
  async fetchStoreviewDataForReport({ commit }, id) {
    try {
      const {
        data: { data, status },
      } = await DataService.get(`storeviews?client_id=${id}`);

      if (status === 'success') {
        commit('setStoreviewsForReport', data);
      }
    } catch (err) {
      commit('setReportError', err);
    }
  },
  async getReportingData({ commit }, payload) {
    try {
      const { data } = await DataService.get('/reports', payload);
      commit('setReportingData', data?.data?.total ? data.data.total : 0);
      commit('setOrderNumbers', data?.data?.order_numbers ? data.data.order_numbers : 0);
      commit('setEnquiryNumbers', data?.data?.enquiry_numbers ? data.data.enquiry_numbers : 0);
    } catch (err) {
      commit('setReportError', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
