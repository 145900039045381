const initialEnquiry = {
  id: -1,
  email: '',
  client_id: null,
  store_id: null,
  first_name: '',
  last_name: '',
  item_required: 0,
  message: '',
  phone: '',
  vin: '',
  status: 'open',
};

export default {
  fetchEnquiriesBegin(state) {
    state.loading = true;
  },
  fetchEnquiriesSuccess(state, { data, current_page, total, per_page }) {
    state.enquiries = data;
    state.pagination.currentPage = current_page;
    state.pagination.total = total;
    state.pagination.perPage = per_page;
    state.loading = false;
  },
  fetchEnquirySuccess(state, enquiry) {
    // TODO: should get object directly
    state.enquiry = enquiry[0];
    state.loading = false;
  },
  updatePagination(state, { key, value }) {
    state.pagination[key] = value;
  },
  initEnquiry(state) {
    state.enquiry = initialEnquiry;
  },
  storeviewsSavingBegin(state) {
    state.saving = true;
  },
  storeviewsSavingEnd(state) {
    state.saving = false;
    state.loading = false;
  },
  setError(state, err) {
    if (err) state.error = 'There was an error processing this request';
    state.loading = false;
    state.saving = false;
  },
  setEnquiriesSearchEmail(state, key) {
    state.searchKey = key;
  },
};
