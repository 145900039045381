import { makeTreeObjectFromList, sortTreeObject, makeTreeFromObject } from '@/utility/utility';

export default {
  productNationalCodes(state) {
    if (state.product?.id) {
      const originNationalCodes = state.product.custom_attributes.find((code) => {
        return code.attribute_code === 'national_code';
      });

      return originNationalCodes ? originNationalCodes.value.split('|') : [];
    }

    return [];
  },
  productMloCodes(state) {
    if (state.product?.id) {
      const originNationalCodes = state.product.custom_attributes.find((code) => {
        return code.attribute_code === 'mlo_code';
      });
      return originNationalCodes ? originNationalCodes.value.split('|') : [];
    }

    return [];
  },
  nationalCodesTree(state, getters) {
    const treeKeys = ['Make', 'undefined', 'Body_style', 'Fuel', 'Trim'];
    const treeObject = makeTreeObjectFromList(state.allNationalCodes, treeKeys);

    return sortTreeObject(makeTreeFromObject(treeObject, getters.productNationalCodes));
  },
};
