import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  coupons: [],
  salesRule: {},
  rule: [],
  loading: false,
  saving: false,
  visible: false,
  error: null,
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
});

const actions = {
  async fetchCoupons({ commit, state, rootState }) {
    commit('fetchCouponsBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let searchCriteria = {
        pageSize: state.pagination.perPage,
        currentPage: state.pagination.currentPage,
        filterGroups: [
          // {
          //   filters: [
          //     {
          //       field: 'store_id',
          //       value: activeStoreView.store_id,
          //     },
          //   ],
          // },
        ],
        sortOrders: [
          {
            field: 'coupon_id',
            direction: 'DESC',
          },
        ],
      };

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/marketing/getAllCoupons`, {
        searchCriteria,
      });
      if (status === 'success') {
        commit('fetchCouponsDataSuccess', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchSalesRule({ commit, rootState }, ruleId) {
    commit('fetchCouponsBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/marketing/getSalesRule/${ruleId}`);
      if (status === 'success') {
        commit('fetchSalesRuleDataSuccess', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
