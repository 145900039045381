import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from '@/config/cookieKeys';

const TokenService = {
  get: () => Cookies.get(ACCESS_TOKEN),

  set: (value) => {
    Cookies.set(ACCESS_TOKEN, value);
  },

  remove: () => {
    Cookies.remove(ACCESS_TOKEN);
  },
};

export default TokenService;
