import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  storeviews: [],
  storeview: {
    id: -1,
    code: '',
    store_id: '',
    company_id: null,
    payment_provider: null,
    api_key_1: null,
    api_key_2: null,
    payment_additional_1: null,
    payment_additional_2: null,
    payment_additional_3: null,
    es_url: null,
    es_index: null,
    es_username: null,
    es_password: null,
    vsf_url: null,
    vsf_preview: null,
    website_id: '',
    webhook_token: '',
    language: '',
    currency: '',
    currency_code: '',
    shipment_with_label: false,
    shipment_without_label: false,
    shipment_without_tracking: false,
    default_provider: '',
    paypal: {
      id: -1,
      client_id: '',
      client_secret: '',
      public_key: '',
      client_id_sandbox: '',
      client_secret_sandbox: '',
      public_key_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
    stripe: {
      public_api_key: '',
      secret_api_key: '',
      webhook_secret: '',
      public_api_key_sandbox: '',
      secret_api_key_sandbox: '',
      webhook_secret_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
    cybersource: {
      merchant_id: '',
      key: '',
      shared_secret_key: '',
      merchant_id_sandbox: '',
      key_sandbox: '',
      shared_secret_key_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
    checkoutcom: {
      public_api_key: '',
      secret_api_key: '',
      webhook_secret: '',
      public_api_key_sandbox: '',
      secret_api_key_sandbox: '',
      webhook_secret_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
    checkoutcom2: {
      public_api_key: '',
      secret_api_key: '',
      webhook_secret: '',
      public_api_key_sandbox: '',
      secret_api_key_sandbox: '',
      webhook_secret_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
    barclaysenterprise: {
      client_id: '',
      secret_key: '',
      enterprise: '',
      client_id_sandbox: '',
      secret_key_sandbox: '',
      enterprise_sandbox: '',
      status: false,
      manual_capture: false,
      no_capture: false,
      refund_in_platform: false,
      sandbox: false,
    },
  },
  visibleEditModal: false,
  loading: false,
  error: null,
  saving: false,
  filterData: {},
});

const actions = {
  async fetchStoreviews({ commit }) {
    commit('fetchStoreviewsBegin');
    try {
      const {
        data: { data, status },
      } = await DataService.get(`/storeviews`);

      if (status === 'success') {
        commit('fetchStoreviewsSuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async deleteStoreview({ commit, dispatch }, { id }) {
    commit('fetchStoreviewsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/storeviews/${id}`);

      if (status === 'success') {
        dispatch('fetchStoreviews');
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async updateStoreview({ commit, dispatch, state }) {
    commit('fetchStoreviewsBegin');
    try {
      if (state.storeview.id > 0) {
        const { id, ...payload } = state.storeview;
        const {
          data: { status },
        } = await DataService.put(`/storeviews/${id}`, {
          ...payload,
          store_id: +payload.store_id,
          company_id: +payload.company_id,
        });

        if (status === 'success') {
          dispatch('fetchStoreviews');
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        const { id, ...payload } = state.storeview;
        const {
          data: { status },
        } = await DataService.post(`/storeviews`, {
          ...payload,
          store_id: +payload.store_id,
          company_id: +payload.company_id,
        });

        if (status === 'success') {
          dispatch('fetchStoreviews');
        }
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
