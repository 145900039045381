import orderBy from 'lodash.orderby';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

/**
 * Return Object
 * @param {Array} data
 * @param {Array} keys
 */

const makeTreeObjectFromList = (data, keys) => {
  if (keys.length == 0) return data;

  // The current key to perform the grouping on:
  const key = keys[0];

  // Loop through the data and construct buckets for
  // all of the unique keys:
  const groups = {};
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const groupValue = row[key];

    if (groups[groupValue] == undefined) {
      groups[groupValue] = new Array();
    }

    groups[groupValue].push(row);
  }

  // Remove the first element from the groups array:
  keys.shift();

  // If there are no more keys left, we're done:
  if (keys.length == 0) return groups;

  // Otherwise, handle further groupings:
  for (let group in groups) {
    groups[group] = makeTreeObjectFromList(groups[group], keys.slice());
  }

  return groups;
};

/**
 * Return Object
 * @param {Object} obj
 */

const sortTreeObject = (obj) =>
  orderBy(
    obj,
    [
      (item) => {
        item = orderBy(item, 'title', 'asc');
        if (item.children) {
          sortTreeObject(item.children);
        }
      },
    ],
    'asc',
  );

/**
 * Return Array
 * @param {Object} obj
 * @param {Array} selectedItems
 */

const makeTreeFromObject = (obj, levelKey) => {
  let treeItem = [];
  const createChildren = (treeItem, data, levelKey) => {
    Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        // data[key].forEach(d => {
        //   result.children.push({
        //     title: d['mlo_code'],
        //     key: `mlo_code_${d['mlo_code']}`,
        //     // state: {
        //     // checked: true,
        //     // },
        //     isLeaf: true,
        //   });
        // });
        // treeItem.push(result);
        treeItem.push({
          title: key,
          key: `mlo_code_${data[key][0]['mlo_code']}`,
          isLeaf: true,
        });
        return;
      }

      let result = {
        title: key,
        key: levelKey + '_' + key,
        disable: true,
        children: [],
      };

      createChildren(result.children, data[key], levelKey + '_' + key);
      treeItem.push(result);
    });
  };
  createChildren(treeItem, obj, levelKey);

  return treeItem;
};

const fileSize = (size) => {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  }
  return size.toString() + ' B';
};

export { ellipsis, makeTreeObjectFromList, sortTreeObject, makeTreeFromObject, fileSize };
