import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  enquiries: [],
  enquiry: {
    id: -1,
    code: '',
    store_id: '',
  },
  loading: false,
  saving: false,
  searchKey: '',
  error: null,
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
});

const actions = {
  async fetchEnquiries({ commit, state, rootState }) {
    commit('fetchEnquiriesBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { store_id, code } = rootState.auth.activeStoreView;
      const {
        data: { data, status },
      } = await DataService.get(`${code}/enquiries/${client_id}/${store_id}?page=${state.pagination.currentPage}`);

      if (status === 'success') {
        commit('fetchEnquiriesSuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async fetchEnquiry({ commit, rootState }, id) {
    commit('fetchEnquiriesBegin');
    const { code } = rootState.auth.activeStoreView;

    try {
      const {
        data: { data, status },
      } = await DataService.get(`${code}/enquiries`, {
        id,
      });

      if (status === 'success') {
        commit('fetchEnquirySuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async deleteEnquiry({ commit, dispatch, rootState }, id) {
    commit('fetchEnquiriesBegin');
    const { code } = rootState.auth.activeStoreView;

    try {
      const {
        data: { status },
      } = await DataService.delete(`${code}/enquiries/${id}`);

      if (status === 'success') {
        dispatch('fetchEnquiries');
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async updateEnquiry({ commit, dispatch, state, rootState }) {
    commit('fetchEnquiriesBegin');
    try {
      const { store_id, code } = rootState.auth.activeStoreView;
      const client_id = rootState.auth.user.client_id;
      const payload = {
        ...state.enquiry,
        client_id,
        store_id,
      };
      delete payload.id;

      if (state.enquiry.id > 0) {
        const {
          data: { status },
        } = await DataService.put(`${code}/enquiries/${state.enquiry.id}`, payload);

        if (status === 'success') {
          dispatch('fetchEnquiry');
        }
      } else {
        const {
          data: { status },
        } = await DataService.post(`/enquiries`, payload);

        if (status === 'success') {
          dispatch('fetchEnquiry');
        }
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
