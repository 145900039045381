export default {
  setProductFilterVisible(state, value) {
    state.productFilterVisible = value;
  },
  setStateChanged(state, data) {
    state.stateChanged = data;
  },
  setWebsites(state, data) {
    state.websites = data;
  },
  setProductStatus(state, value) {
    state.product.status = value;
  },
  setWidths(state, value) {
    state.widths = value;
  },
  setRims(state, value) {
    state.rims = value;
  },
  setProfiles(state, value) {
    state.profiles = value;
  },
  setNationalCode(state, value) {
    state.national_code = value?.length ? value[0]?.national_code : value?.national_code;
  },
  setNationalCodeFromProduct(state) {
    let custom_attributes = state.product?.custom_attributes;
    if (custom_attributes?.length) {
      let nationalData = custom_attributes.find((attr) => attr.attribute_code === 'national_code');
      state.national_code = nationalData?.value;
    }
  },
  setNationalCodeToProduct(state) {
    let custom_attributes = state.product?.custom_attributes;
    if (custom_attributes?.length) {
      custom_attributes = custom_attributes.filter((attr) => attr.attribute_code !== 'national_code');
      custom_attributes.push({ attribute_code: 'national_code', value: state.national_code });
    }
    state.product.custom_attributes = custom_attributes;
    console.log(state.product, 'product');
  },
  setShowModal(state, value) {
    state.showModal = value;
  },
  setChanged(state, value) {
    state.isChanged = value;
  },
  setShow(state, value) {
    state.isShow = value;
  },
  fetchProductsBegin(state) {
    state.loading = true;
  },
  fetchProductsSuccess(state, { items, total_count }) {
    state.loading = false;
    state.products = items;
    state.totalCount = total_count;
    console.log('here', state.currentPage, total_count / state.pageSize);
    if (state.currentPage && state.currentPage > total_count / state.pageSize) {
      state.currentPage = 1;
    }
  },

  fetchBegin(state) {
    state.loading = true;
  },
  productSavingBegin(state) {
    state.saving = true;
  },
  setProduct(state, data) {
    state.product = data;
  },
  setChildProducts(state, data) {
    state.childProducts = data;
  },
  setAttributes(state, data) {
    state.attributes = data;
  },
  setError(state, err) {
    state.loading = false;
    if (err) state.error = 'There was an error processing this request';
    state.products = [];
    state.product = {};
    state.totalCount = 0;
    state.pageSize = 12;
    state.saving = false;
    state.currentPage = 1;
  },
  setAllNationalCodes(state, data) {
    state.allNationalCodes = data;
  },
  fetchEnd(state) {
    state.loading = false;
  },
  productSavingEnd(state) {
    state.saving = false;
  },
  setProductFilters(state, filters) {
    state.filters = { ...filters };
  },
  setFiltersData(state, filters) {
    state.filterData = { ...filters };
  },
  setFiltersData1(state, filters) {
    state.filterData1 = { ...filters };
  },
  clearProductFilters(state) {
    state.filters = {
      final_price: {
        from: '',
        to: '',
      },
      quantity: {
        from: '',
        to: '',
      },
      name: '',
      sku: '',
      status: null,
      product_type: [],
    };
  },
  childProductLoadingBegin(state) {
    state.childProductLoading = true;
  },
  childProductLoadingEnd(state) {
    state.childProductLoading = false;
  },

  setAttributeOptions(state, value) {
    state.attributeOptions = value;
  },
};
