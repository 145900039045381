import TokenService from '../../../utility/tokenControl';
import Cookies from 'js-cookie';
import { ACTIVE_STORE_VIEW } from '@/config/cookieKeys';

export default {
  loginBegin(state) {
    state.loading = true;
  },
  loginSuccess(state, { token, user }) {
    TokenService.set(token);
    state.user = user;
    state.isAuthenticated = true;
    state.activeStoreView = user.store_views.length && user.store_views[0];
    Cookies.set(ACTIVE_STORE_VIEW, state.activeStoreView);
    state.loading = false;
  },

  setToken(state, token) {
    TokenService.set(token);
    state.loading = false;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state) {
    TokenService.remove();
    Cookies.remove(ACTIVE_STORE_VIEW);
    state.isAuthenticated = false;
    state.user = null;
    state.loading = false;
  },

  setAuthErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  setActiveStoreView(state, payload) {
    state.activeStoreView = payload;
  },

  setMe(state, user) {
    state.user = user;
    state.isAuthenticated = true;
    state.activeStoreView = user.store_views.length && user.store_views[0];
  },
};
