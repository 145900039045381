import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import router from '@/routes/protectedRoute';

const state = () => ({
  clients: [],
  client: {},
  location: {},
  user: {},
  resource: {},
  attribute: {},
  attributeGroup: {},
  visibleLocationEditModal: false,
  visibleAttributesEditModal: false,
  visibleAttributesGroupEditModal: false,
  visibleResourceEditModal: false,
  visibleUserEditModal: false,
  loading: false,
  error: null,
  saving: false,
  allStoreviews: [],
  allScopes: [],
  allRoles: [],
});

const actions = {
  async fetchClients({ commit }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { data, status },
      } = await DataService.get(`/companies`);

      if (status === 'success') {
        commit('fetchClientsSuccess', data);
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async fetchClient({ commit }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { data, status },
      } = await DataService.get(`/companies/${id}`);

      let res = await DataService.get(`/roles`);
      if (res.data.status === 'success') {
        commit('setRoles', res.data.data);
      }
      res = await DataService.get(`/scopes`);
      if (res.data.status === 'success') {
        commit('setScopes', res.data.data);
      }
      res = await DataService.get(`/storeviews`);
      if (res.data.status === 'success') {
        commit('setStoreviews', res.data.data);
      }

      if (status === 'success') {
        commit('fetchClientSuccess', data);
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteClient({ commit, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/companies/${id}`);

      if (status === 'success') {
        dispatch('fetchClients');
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateClient({ commit, state }, { id }) {
    commit('fetchClientsBegin');
    try {
      // eslint-disable-next-line no-unused-vars
      const { locations, users, ...payload } = state.client;

      const {
        data: { status, data },
      } = await DataService.put(`/companies/${id}`, payload);

      if (status === 'success') {
        commit('fetchClientSuccess', data);
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createClient({ commit, state }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { data, status },
      } = await DataService.post(`/companies`, state.client);

      if (status === 'success') {
        commit('fetchClientSuccess', data);
        router.push({
          name: 'omni-client-information',
          params: { id: data.id },
        });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteLocation({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/locations/${state.client.id}/${id}`);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateLocation({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.put(`/locations/${state.client.id}/${id}`, state.location);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateAttribute({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.put(`/attributes/${state.client.id}/${id}`, state.attribute);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createAttribute({ commit, state, dispatch }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    const { id, ...payload } = state.attribute;
    try {
      const {
        data: { status },
      } = await DataService.post(`/attributes/${state.client.id}`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createAttributeGroup({ commit, state, dispatch }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    const { id, ...payload } = state.attributeGroup;
    try {
      const {
        data: { status },
      } = await DataService.post(`/attributeGroup/${state.client.id}`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateAttributeGroup({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.put(`/attributeGroup/${state.client.id}/${id}`, state.attributeGroup);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createResource({ commit, state, dispatch }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    const { id, ...payload } = state.resource;
    try {
      const {
        data: { status },
      } = await DataService.post(`/resource/${state.client.id}`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateResource({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.put(`/resource/${state.client.id}/${id}`, state.resource);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createLocation({ commit, state, dispatch }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    const { id, ...payload } = state.location;
    try {
      const {
        data: { status },
      } = await DataService.post(`/locations/${state.client.id}`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteUser({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/users/${state.client.id}/${id}`);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteAttribute({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/attributes/${state.client.id}/${id}`);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteResource({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/resource/${state.client.id}/${id}`);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async deleteAttributeGroup({ commit, state, dispatch }, { id }) {
    commit('fetchClientsBegin');
    try {
      const {
        data: { status },
      } = await DataService.delete(`/attributeGroup/${state.client.id}/${id}`);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async updateUser({ commit, state, dispatch }, { id: userId, storeviews }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    let { id, ...payload } = state.user;
    try {
      payload.scopes = payload.scopes.map((scope) => state.allScopes.find((s) => s.name === scope)['id']);
      payload.roles = payload.roles.map((role) => state.allRoles.find((r) => r.name === role)['id']);
      payload.store_views = storeviews;
      payload.locations = payload.locations.map(
        (location) => state.client.locations.find((l) => l.location_name === location)['id'],
      );
      payload = {
        ...payload,
        company_name: state.client.name,
      };
      const {
        data: { status },
      } = await DataService.put(`/users/${state.client.id}/${userId}`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },

  async createUser({ commit, state, dispatch }, { storeviews }) {
    commit('fetchClientsBegin');
    // eslint-disable-next-line no-unused-vars
    let payload = Object.assign({}, state.user);
    try {
      payload.scopes = payload.scopes.map((scope) => state.allScopes.find((s) => s.name === scope)['id']);
      payload.roles = payload.roles.map((role) => state.allRoles.find((r) => r.name === role)['id']);
      payload.store_views = storeviews;
      payload.locations = payload.locations.map(
        (location) => state.client.locations.find((l) => l.location_name === location)['id'],
      );
      payload = {
        ...payload,
        company_name: state.client.name,
      };
      delete payload.id;

      const {
        data: { status },
      } = await DataService.post(`/auth/register`, payload);

      if (status === 'success') {
        await dispatch('fetchClient', { id: state.client.id });
      }
    } catch ({ data }) {
      commit('setClientsError', data.error);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
