import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  appointmentsData: [],
  appointments: [],
  appointment: {
    id: -1,
    start_time: '',
    end_time: null,
    orderid: null,
    booked_online: '',
    slot_id: '',
    note: 0,
    internal_booking: 0,
    technical_id: '',
  },
  loading: false,
  modalLoading: false,
  saving: false,
  searchKey: '',
  error: null,
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
  currentDate: '',
  appointmentData: {
    id: -1,
    customer: '',
    start_time: '',
    end_time: null,
    order_id: null,
    booked_online: '',
    note: '',
    internal_booking: 0,
    technician_id: [],
    isEdit: false,
  },
  slotData: [],
  technicianData: [],
  slot_id: -1,
  filters: {
    customer: '',
    technician: [],
    from: '',
    to: '',
    orderId: '',
  },
  slot_ids: [],
  isEdit: false,
  hasOrder: false,
  weeks: [],
});

const actions = {
  async saveAppointmentData({ dispatch }, payload) {
    payload.id = payload.slot_id;
    dispatch('setAppointment', payload);
  },
  async fetchSlotIDS({ commit, rootState }, date) {
    const client_id = rootState.auth.user.client_id;
    const { code } = rootState.auth.activeStoreView;

    const {
      data: { data, status },
    } = await DataService.get(`${code}/appointments/${client_id}/fetchSlotData`, { date });

    if (status === 'success') {
      commit('fetchSlotDataDataSuccess', data);
    } else {
      commit('setError', data.error);
    }
  },

  async fetchTechnicians({ commit, rootState }) {
    const client_id = rootState.auth.user.client_id;
    const { code } = rootState.auth.activeStoreView;

    const {
      data: { data, status },
    } = await DataService.get(`${code}/appointments/${client_id}/fetchTechnicians`);

    if (status === 'success') {
      commit('fetchTechniciansDataSuccess', data);
    } else {
      commit('setError', data.error);
    }
  },

  async fetchAppointments({ commit, state, rootState }) {
    const scopes = rootState.auth.user.scopes.map((scope) => scope.name);
    const hasAppointmentScope = scopes.includes('appointments');
    if (!hasAppointmentScope) {
      await commit('fetchAppointmentsDataSuccess', []);
      return;
    }

    commit('fetchAppointmentsBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      const order = rootState.orders.order;
      let url = `${code}/appointments/${client_id}/getAllAppointments?page=${state.pagination.currentPage}`;
      if (order && state.hasOrder) {
        url = `${code}/appointments/${client_id}/getAllAppointments?orderId=${order?.entity_id}`;
        const {
          data: { data, status },
        } = await DataService.get(url);
        if (status === 'success') {
          commit('fetchAppointmentsDataSuccess', data);
        }
        return;
      }

      const {
        data: { data, status },
      } = await DataService.get(url, state.filters);

      if (status === 'success') {
        commit('fetchAppointmentsDataSuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async fetchAppointment({ commit, rootState }, date) {
    commit('fetchAppointmentBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      let params = {
        clientID: client_id,
        show: '5 days',
        date,
        timezone: '',
      };
      const {
        data: { data, status },
      } = await DataService.get(`${code}/appointments/${client_id}`, params);

      if (status === 'success') {
        commit('fetchAppointmentsSuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async fetchAppointmentData({ commit, rootState }, id) {
    commit('fetchAppointmentsBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      const {
        data: { data, status },
      } = await DataService.get(`${code}/appointments/${client_id}/getAppointment/${id}`);
      console.log(data, 'data');
      if (status === 'success') await commit('fetchAppointmentDataSuccess', data);
    } catch ({ data }) {
      // commit('setError', data.error);
    }
  },

  async fetchAppointmentDataWithOrder({ rootState, commit }) {
    const scopes = rootState.auth.user.scopes.map((scope) => scope.name);
    const hasAppointmentScope = scopes.includes('appointments');
    if (!hasAppointmentScope) {
      await commit('fetchAppointmentsDataSuccess', []);
      return;
    }

    commit('fetchAppointmentsBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      const order = rootState.orders.order;
      const {
        data: { data, status },
      } = await DataService.get(`${code}/appointments/${client_id}/getAllAppointments?orderId=${order?.entity_id}`);
      if (status === 'success') await commit('fetchAppointmentsDataSuccess', data);
    } catch ({ data }) {
      // commit('setError', data.error);
    }
  },

  async setAppointment({ commit, rootState, state, dispatch }, payload) {
    commit('fetchAppointmentsBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      payload.slot_ids = state.slot_ids;
      if (state.isEdit) {
        let technician_id = payload.technician_id;
        console.log(technician_id, 'technician_id');
        if (technician_id.length > 0) {
          technician_id = technician_id[0];
          payload.technician_id = technician_id;
        }
        console.log(payload, 'payload');
      }
      const {
        data: { data, status },
      } = await DataService.post(`${code}/appointments/${client_id}`, payload);
      console.log(data, 'data');
      if (status === 'success') {
        dispatch('fetchAppointment', rootState.appointments.currentDate);
        if (rootState.appointments.hasOrder) {
          dispatch('fetchAppointmentDataWithOrder');
        } else {
          dispatch('fetchAppointments');
        }
      } else {
        commit('setError', data.error);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async deleteAppointment({ commit, dispatch, rootState }, payload) {
    commit('fetchAppointmentsBegin');
    try {
      const client_id = rootState.auth.user.client_id;
      const { code } = rootState.auth.activeStoreView;
      const {
        data: { data, status },
      } = await DataService.delete(`${code}/appointments/${client_id}`, { appointment_id: payload });
      console.log(data, 'data');
      if (status === 'success') {
        dispatch('fetchAppointment', rootState.appointments.currentDate);
        dispatch('fetchAppointments');
      } else {
        commit('setError', data.error);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  // async fetchEnquiry({ commit, rootState }, id) {
  //   commit('fetchEnquiriesBegin');
  //   const { code } = rootState.auth.activeStoreView;

  //   try {
  //     const {
  //       data: { data, status },
  //     } = await DataService.get(`${code}/enquiries`, {
  //       id,
  //     });

  //     if (status === 'success') {
  //       commit('fetchEnquirySuccess', data);
  //     }
  //   } catch ({ data }) {
  //     commit('setError', data.error);
  //   }
  // },

  // async deleteEnquiry({ commit, dispatch, rootState }, id) {
  //   commit('fetchEnquiriesBegin');
  //   const { code } = rootState.auth.activeStoreView;

  //   try {
  //     const {
  //       data: { status },
  //     } = await DataService.delete(`${code}/enquiries/${id}`);

  //     if (status === 'success') {
  //       dispatch('fetchEnquiries');
  //     }
  //   } catch ({ data }) {
  //     commit('setError', data.error);
  //   }
  // },

  // async updateEnquiry({ commit, dispatch, state, rootState }) {
  //   commit('fetchEnquiriesBegin');
  //   try {
  //     const { store_id, code } = rootState.auth.activeStoreView;
  //     const client_id = rootState.auth.user.client_id;
  //     const payload = {
  //       ...state.enquiry,
  //       client_id,
  //       store_id,
  //     };
  //     delete payload.id;

  //     if (state.enquiry.id > 0) {
  //       const {
  //         data: { status },
  //       } = await DataService.put(`${code}/enquiries/${state.enquiry.id}`, payload);

  //       if (status === 'success') {
  //         dispatch('fetchEnquiry');
  //       }
  //     } else {
  //       const {
  //         data: { status },
  //       } = await DataService.post(`/enquiries`, payload);

  //       if (status === 'success') {
  //         dispatch('fetchEnquiry');
  //       }
  //     }
  //   } catch ({ data }) {
  //     commit('setError', data.error);
  //   }
  // },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
