const initialLocation = {
  id: -1,
  location_name: '',
  phone: '',
  location_order_id: 0,
  vsf_store_id: 0,
  country: '',
  region: '',
  city: '',
  street: '',
  postcode: '',
  brand: '',
  collection: 0,
  delivery: 0,
  fitment: 0,
  is_hub: 0,
  latitude: '',
  longitude: '',
  print_label: null,
  api_url: null,
  api_token: null,
  api_user: null,
};

const initialAttribute = {
  id: -1,
  name: '',
  code: '',
  group: '',
  used_as_product_option: false,
  variant_product_field: false,
  details: '',
  used_for_filter: false,
  equal_filter: false,
  contain_filter: false,
  blank_filter: false,
};

const initialAttributeGroup = {
  id: -1,
  attribute_id: '',
  name: '',
  store_views: [],
  product_tool_1: false,
  product_tool_2: false,
  product_tool_3: false,
  product_tool_4: false,
};

const initialResource = {
  id: -1,
  timezone: '',
  working_days: '',
  name: '',
  store_views: '',
};

const initialUser = {
  id: -1,
  name: '',
  email: '',
  password: '',
  is_admin: 0,
  scopes: [],
  roles: [],
  store_views: [],
  locations: [],
  email_only: false,
};

export default {
  fetchClientsBegin(state) {
    state.loading = true;
  },
  fetchClientsEnd(state) {
    state.loading = false;
  },
  fetchClientSuccess(state, data) {
    state.loading = false;
    Object.assign(state.client, data);
  },
  fetchClientsSuccess(state, data) {
    state.loading = false;
    Object.assign(state.clients, data);
  },
  setClientsError(state, err) {
    state.loading = false;
    state.error = err;
    state.saving = false;
  },
  setClient(state, payload) {
    state.client = payload;
  },
  setLocation(state, payload) {
    Object.assign(state.location, payload);
  },
  setAttribute(state, payload) {
    Object.assign(state.attribute, payload);
  },
  setAttributeGroup(state, payload) {
    Object.assign(state.attributeGroup, payload);
  },
  initLocation(state) {
    Object.assign(state.location, initialLocation);
  },
  initAttribute(state) {
    Object.assign(state.attribute, initialAttribute);
  },
  initAttributeGroup(state) {
    Object.assign(state.attributeGroup, initialAttributeGroup);
  },
  initResource(state) {
    Object.assign(state.resource, initialResource);
  },
  setUser(state, payload) {
    if (!payload.password) {
      Object.assign(state.user, { ...payload, password: '' });
    } else {
      Object.assign(state.user, payload);
    }
  },
  setResource(state, payload) {
    Object.assign(state.resource, payload);
  },
  initUser(state) {
    Object.assign(state.user, initialUser);
  },
  toggleLocationEditModal(state, payload) {
    state.visibleLocationEditModal = payload;
  },
  toggleUserEditModal(state, payload) {
    state.visibleUserEditModal = payload;
  },
  toggleAttributeEditModal(state, payload) {
    state.visibleAttributesEditModal = payload;
  },
  toggleAttributeGroupEditModal(state, payload) {
    state.visibleAttributesGroupEditModal = payload;
  },
  toggleResourceEditModal(state, payload) {
    state.visibleResourceEditModal = payload;
  },
  setScopes(state, data) {
    state.allScopes = data;
  },
  setStoreviews(state, data) {
    state.allStoreviews = data;
  },
  setRoles(state, data) {
    state.allRoles = data;
  },
};
