export default {
  fetchCustomerDataBegin(state) {
    state.loading = true;
  },
  fetchCustomersSuccess(state, { items, total_count }) {
    state.loading = false;
    state.customers = items;
    state.totalCount = total_count;
  },
  fetchCustomerSuccess(state, { customer, shippingAddress = null, billingAddress = null }) {
    state.loading = false;
    state.saving = false;
    state.customer = customer;
    state.shippingAddress = Array.isArray(shippingAddress) || !!shippingAddress ? {} : shippingAddress;
    state.billingAddress = Array.isArray(billingAddress) || !!billingAddress ? {} : billingAddress;
  },
  saveCustomerDataBegin(state) {
    state.saving = true;
  },

  setCustomerError(state, err) {
    state.loading = false;
    state.error = err;
  },

  setSearchCustomerEmail(state, email) {
    state.searchKey = email;
  },
};
