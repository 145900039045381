import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import router from '@/routes/protectedRoute';
import { Notification } from 'ant-design-vue';
import app from '@/config/configApp';

const state = () => ({
  isAuthenticated: false,
  loading: false,
  error: null,
  user: null,
  activeStoreView: null,
});

const actions = {
  async login({ commit }, { email, companyName, password }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post('auth/login', {
        email,
        company_name: companyName,
        password,
      });
      if (data.user.is_admin) {
        commit('loginSuccess', data);
        router.push({ name: 'omni-clients-list' });
      } else {
        if (data.user.first_login === 1) {
          const {
            data: { status },
          } = await DataService.post(`auth/forgot-password`, {
            email,
          });

          if (status === 'success') {
            Notification['success']({
              message: 'You’ll receive an email with your password reset.',
              duration: 10,
            });
          }
        } else if (!data.user.mfa) {
          commit('loginSuccess', data);
          router.push({ name: 'omni-orders-list' });
        } else {
          commit('loginSuccess', data);
          router.push({ name: 'passcode', params: { id: data.user.id } });
        }
      }
    } catch ({ data }) {
      commit('setAuthErr', data.error);
    }
  },
  async loginWithPasscode({ commit }, { id, passcode }) {
    try {
      commit('loginBegin');
      const { data } = await DataService.post('auth/passcodeLogin', {
        id,
        passcode,
      });
      if (data.status === 'success') {
        commit('loginSuccess', data);
        router.push({ name: 'omni-orders-list' });
      }
    } catch ({ data }) {
      commit('setAuthErr', data.message);
      if (data.error === 'could_not_found_passcode' || data.error === 'passcode_expired') {
        router.push({ name: 'login' });
      }
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      await DataService.post('auth/logout');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('setAuthErr', err);
    }
  },
  async getMe({ commit }) {
    try {
      const { data } = await DataService.get('me');
      commit('setMe', data);

      const { $cookies } = app.config.globalProperties;
      const activeStoreView = $cookies.get('active_store_view');
      commit('setActiveStoreView', activeStoreView);
    } catch (err) {
      const { status, data } = err;
      if (status === 401) {
        router.push({ name: 'login' });
      }
      commit('setAuthErr', data.error);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
