export default [
  {
    name: 'omni',
    path: 'omni/products',
    component: () => import(/* webpackChunkName: "login" */ '@/view/omni/productsTable/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-products',
        component: () =>
          import(/* webpackChunkName: "omni-products" */ '@/view/omni/productsTable/ProductListDataTable.vue'),
      },
    ],
  },
  {
    path: 'omni/product/:sku',
    name: 'omni-product',
    component: () => import(/* webpackChunkName: "product" */ '@/view/omni/product/Index.vue'),
    children: [
      {
        path: 'product-details',
        name: 'omni-product-details',
        component: () => import(/* webpackChunkName: "product-details" */ '@/view/omni/product/ProductDetails.vue'),
      },
      {
        path: 'national-codes',
        name: 'omni-national-codes',
        component: () => import(/* webpackChunkName: "product-details" */ '@/view/omni/product/NationalCodes.vue'),
      },
      {
        path: 'tyres',
        name: 'omni-tyres',
        component: () => import(/* webpackChunkName: "product-details" */ '@/view/omni/product/Tyre.vue'),
      },
      {
        path: 'media-upload',
        name: 'omni-media-upload',
        component: () => import(/* webpackChunkName: "product-details" */ '@/view/omni/product/MediaUpload.vue'),
      },
      {
        path: 'child-products',
        name: 'omni-child-products',
        component: () => import(/* webpackChunkName: "product-details" */ '@/view/omni/product/ChildProducts.vue'),
      },
    ],
  },
  {
    path: 'omni/product/create',
    name: 'omni-product-create',
    component: () => import(/* webpackChunkName: "omni-product-create" */ '@/view/omni/product/create/Index.vue'),
  },
  {
    name: 'omni-orders',
    path: 'omni/orders',
    component: () => import(/* webpackChunkName: "omni-orders" */ '@/view/omni/ordersTable/Index.vue'),
    meta: { title: 'All Orders' },
    children: [
      {
        path: '',
        name: 'omni-orders-list',
        component: () =>
          import(/* webpackChunkName: "omni-order-list" */ '@/view/omni/ordersTable/OrderListDataTable.vue'),
      },
      {
        path: 'create',
        name: 'omni-order-create',
        component: () => import(/* webpackChunkName: "omni-order-list" */ '@/view/omni/ordersTable/OrderCreate.vue'),
      },
      {
        path: 'open-carts',
        name: 'omni-order-open-carts',
        component: () =>
          import(/* webpackChunkName: "omni-invoice" */ '@/view/omni/ordersTable/overview/order/opencarts/Index.vue'),
      },
      {
        path: ':id',
        name: 'omni-order-details',
        component: () =>
          import(/* webpackChunkName: "omni-order-details" */ '@/view/omni/ordersTable/overview/order/Index.vue'),
        children: [
          {
            path: 'information',
            name: 'omni-order-information',
            component: () =>
              import(
                /* webpackChunkName: "omni-order-information" */ '@/view/omni/ordersTable/overview/order/information/Index.vue'
              ),
          },
          {
            path: 'invoices',
            name: 'omni-order-invoices',
            component: () =>
              import(
                /* webpackChunkName: "omni-order-invoices" */ '@/view/omni/ordersTable/overview/order/invoices/Index.vue'
              ),
          },
          {
            path: 'refunds',
            name: 'omni-order-refunds',
            component: () =>
              import(
                /* webpackChunkName: "omni-order-refunds" */ '@/view/omni/ordersTable/overview/order/refunds/Index.vue'
              ),
            children: [
              {
                path: '',
                name: 'omni-order-refunds-list',
                component: () =>
                  import(
                    /* webpackChunkName: "omni-order-invoice" */ '@/view/omni/ordersTable/overview/order/refunds/List.vue'
                  ),
              },
              {
                path: ':refundId/details',
                name: 'omni-order-refunds-details',
                component: () =>
                  import(
                    /* webpackChunkName: "omni-order-invoice" */ '@/view/omni/ordersTable/overview/order/refunds/Details.vue'
                  ),
              },
              {
                path: 'create',
                name: 'omni-order-refunds-create',
                component: () =>
                  import(
                    /* webpackChunkName: "omni-order-refund-create" */ '@/view/omni/ordersTable/overview/order/refunds/create/Index.vue'
                  ),
              },
            ],
          },
          {
            path: 'shipments',
            name: 'omni-order-shipments',
            component: () =>
              import(
                /* webpackChunkName: "omni-products" */ '@/view/omni/ordersTable/overview/order/shipments/Index.vue'
              ),
          },
          {
            path: 'shipments/:shipmentId',
            name: 'omni-order-shipment-view',
            component: () =>
              import(
                /* webpackChunkName: "omni-order-shipment-view" */ '@/view/omni/ordersTable/overview/order/shipments/Create.vue'
              ),
          },
          {
            path: 'invoices/:invoiceId',
            name: 'omni-order-invoice-view',
            component: () =>
              import(/* webpackChunkName: "omni-order-invoice-view" */ '@/view/omni/ordersTable/Index.vue'),
            children: [
              {
                path: '',
                name: 'omni-order-invoice-view-create',
                component: () =>
                  import(
                    /* webpackChunkName: "omni-order-invoice" */ '@/view/omni/ordersTable/overview/order/invoices/Create.vue'
                  ),
              },
            ],
          },
          {
            path: 'comments-history',
            name: 'omni-order-comments-history',
            component: () =>
              import(
                /* webpackChunkName: "omni-invoice" */ '@/view/omni/ordersTable/overview/order/CommentsHistory.vue'
              ),
          },
          {
            path: 'order-history',
            name: 'omni-order-history',
            component: () =>
              import(/* webpackChunkName: "omni-invoice" */ '@/view/omni/ordersTable/overview/order/OrderHistory.vue'),
          },
          {
            path: 'transactions',
            name: 'omni-order-transactions',
            component: () =>
              import(/* webpackChunkName: "omni-invoice" */ '@/view/omni/ordersTable/overview/order/Transactions.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'omni-draft-orders',
    path: 'omni/draft-orders',
    component: () => import(/* webpackChunkName: "omni-orders" */ '@/view/omni/ordersTable/Index.vue'),
    meta: { title: 'Draft Orders' },
    children: [
      {
        path: '',
        name: 'omni-draft-orders-list',
        component: () =>
          import(/* webpackChunkName: "omni-order-list" */ '@/view/omni/ordersTable/OrderListDataTable.vue'),
      },
    ],
  },
  {
    name: 'omni-customers',
    path: 'omni/customers',
    component: () => import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-customers-list',
        component: () => import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/CustomersList.vue'),
      },
      {
        path: ':id',
        name: 'omni-customer-details',
        component: () =>
          import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/Index.vue'),
        children: [
          {
            path: 'customer-view',
            name: 'omni-customer-view',
            component: () =>
              import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/CustomerView.vue'),
          },
          {
            path: 'account-information',
            name: 'omni-customer-account-information',
            component: () =>
              import(
                /* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/AccountInformation.vue'
              ),
          },
          {
            path: 'addresses',
            name: 'omni-customer-addresses',
            component: () =>
              import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/Addresses.vue'),
          },
          {
            path: 'orders',
            name: 'omni-customer-orders',
            component: () =>
              import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/Orders.vue'),
          },
          {
            path: 'shopping-cart',
            name: 'omni-customer-shopping-cart',
            component: () =>
              import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/ShoppingCart.vue'),
          },
          {
            path: 'newsletter',
            name: 'omni-customer-newsletter',
            component: () =>
              import(/* webpackChunkName: "omni-products" */ '@/view/omni/customers/overview/details/Newsletter.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'omni-enquiries',
    path: 'omni/enquiries',
    component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/enquiries/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-enquiries-list',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/enquiries/EnquiriesTable.vue'),
      },
      {
        path: ':id',
        name: 'omni-enquiries-details',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/enquiries/overview/Index.vue'),
      },
    ],
  },
  {
    name: 'omni-appointments',
    path: 'omni/appointments',
    component: () => import(/* webpackChunkName: "omni-appointments" */ '@/view/omni/appointments/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-appointments-list',
        component: () =>
          import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/appointments/AppointmentsTable.vue'),
      },
      {
        path: 'details',
        name: 'omni-appointments-details',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/appointments/overview/Index.vue'),
      },
      {
        path: 'edit/:id',
        name: 'omni-appointments-edit',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/appointments/overview/Index.vue'),
      },
    ],
  },
  {
    name: 'omni-marketing',
    path: 'omni/marketing',
    component: () => import(/* webpackChunkName: "omni-appointments" */ '@/view/omni/marketing/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-marketing-list',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/marketing/MarketingTable.vue'),
      },
    ],
  },
  {
    name: 'omni-reporting',
    path: 'omni/reporting',
    component: () => import(/* webpackChunkName: "omni-appointments" */ '@/view/omni/reporting/Index.vue'),
    children: [
      {
        path: '',
        name: 'omni-reporting-dashboard',
        component: () => import(/* webpackChunkName: "omni-Enquiries" */ '@/view/omni/reporting/Dashboard.vue'),
      },
    ],
  },
];
