import mutations from './mutations';
import getters from './mutations';
import { DataService } from '@/config/dataService/dataService';

const state = () => ({
  customer: {},
  billingAddress: {},
  shippingAddress: {},
  customers: [],
  loading: false,
  error: null,
  totalCount: 0,
  pageSize: 20,
  currentPage: 1,
  searchKey: '',
  saving: false,
});

const actions = {
  async fetchCustomers({ commit, rootState, state }) {
    commit('fetchCustomerDataBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let searchCriteria = {
        searchCriteria: {
          pageSize: state.pageSize,
          currentPage: state.currentPage,
          filterGroups: [
            {
              filters: [
                {
                  field: 'email',
                  value: `${state.searchKey}%`,
                  condition_type: 'like',
                },
              ],
            },
          ],
        },
      };

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/customers`, {
        searchCriteria,
      });

      if (status === 'success') {
        commit('fetchCustomersSuccess', data);
      }
    } catch (err) {
      commit('setCustomerError', err);
    }
  },

  async fetchCustomer({ commit, rootState }, { id }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('fetchCustomerDataBegin');
      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/customers/${id}`);
      if (status === 'success') {
        const billingRes = await DataService.get(`${activeStoreView.code}/customers/${id}/billingAddress`);
        const shippingRes = await DataService.get(`${activeStoreView.code}/customers/${id}/shippingAddress`);
        if (billingRes.data.status === 'success' && shippingRes.data.status === 'success') {
          commit('fetchCustomerSuccess', {
            customer: data,
            shippingAddress: shippingRes.data.data,
            billingAddress: billingRes.data.data,
          });
        }
      }
    } catch (err) {
      commit('setCustomerError', err);
    }
  },

  async updateCustomer({ commit, rootState }, { id, newCustomerData }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('saveCustomerDataBegin');
      const {
        data: { data, status },
      } = await DataService.put(`${activeStoreView.code}/customers/${id}`, {
        customer: newCustomerData,
      });

      if (status === 'success') {
        commit('fetchCustomerSuccess', {
          customer: data,
        });
      }
    } catch (err) {
      commit('setCustomerError', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
