import mutations, { initialFilters } from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { Notification } from 'ant-design-vue';
import router from '@/routes/protectedRoute';
const jwt = require('jsonwebtoken');

const state = () => ({
  order: {},
  orders: [],
  shipments: [],
  shipment: null,
  invoices: [],
  invoice: null,
  transactions: [],
  transaction: null,
  refund: null,
  refunds: [],
  error: null,
  totalCount: 0,
  pageSize: 12,
  currentPage: 1,
  searchKey: '',
  loading: false,
  saving: false,
  filters: Object.assign({}, JSON.parse(JSON.stringify(initialFilters))),
  thirdPartyPaymentInfo: {},
  openCarts: [],
});

const actions = {
  async fetchOpenCarts({ commit, rootState, state }) {
    try {
      commit('fetchBegin');
      const activeStoreView = rootState.auth.activeStoreView;
      let searchCriteria = {
        pageSize: state.pageSize,
        currentPage: state.currentPage,
        filterGroups: [],
        sortOrders: [
          {
            field: 'coupon_id',
            direction: 'DESC',
          },
        ],
      };
      let {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/orders/open-carts`, { searchCriteria });
      if (status === 'success') {
        commit('fetchOpenCartsSuccess', data);
      } else {
        commit('setError', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },
  async fetchOrders({ commit, state, rootState }) {
    commit('fetchBegin');
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      let searchCriteria = {
        pageSize: state.pageSize,
        currentPage: state.currentPage,
        filterGroups: [
          {
            filters: [
              {
                field: 'store_id',
                value: activeStoreView.store_id,
              },
            ],
          },
          // {
          //   filters: [
          //     {
          //       custom_attributes: {
          //         field: 'locations_id',
          //         value: '1',
          //       },
          //     },
          //   ],
          // },
        ],
        sortOrders: [
          {
            field: 'entity_id',
            direction: 'DESC',
          },
        ],
      };

      let dateRangeFilters = [];
      if (JSON.stringify(state.filters) !== JSON.stringify(initialFilters)) {
        if (state.filters['created_at']['from']) {
          dateRangeFilters = [
            ...dateRangeFilters,
            {
              filters: [
                {
                  field: 'created_at',
                  value: `${state.filters['created_at']['from']} 00:00:00`,
                  condition_type: 'from',
                },
              ],
            },
          ];
        }
        if (state.filters['created_at']['to']) {
          dateRangeFilters = [
            ...dateRangeFilters,
            {
              filters: [
                {
                  field: 'created_at',
                  value: `${state.filters['created_at']['to']} 23:59:59`,
                  condition_type: 'to',
                },
              ],
            },
          ];
        }

        searchCriteria = {
          ...searchCriteria,
          filterGroups: Object.keys(state.filters).reduce((result, key) => {
            if (key !== 'create_at') {
              if (state.filters[key] && typeof state.filters[key] !== 'object') {
                result = [
                  ...result,
                  {
                    filters: [
                      {
                        field: key,
                        value: `${state.filters[key]}`,
                        condition_type: 'eq',
                      },
                    ],
                  },
                ];
              }
            }

            return result;
          }, searchCriteria.filterGroups),
        };
      }

      if (dateRangeFilters.length) {
        searchCriteria.filterGroups = [...searchCriteria.filterGroups, ...dateRangeFilters];
      }

      const {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/orders`, {
        searchCriteria,
      });
      if (status === 'success') {
        commit('fetchOrdersSuccess', data);
      }
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchOrder({ commit, rootState, state, dispatch }, { entityId, flag = true }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      if (flag && state.order?.entity_id === parseInt(entityId)) {
        return;
      }
      commit('fetchBegin');
      let {
        data: { data, status },
      } = await DataService.get(`${activeStoreView.code}/orders/${entityId}`);
      if (status === 'success') {
        await commit('fetchOrderSuccess', data);
        await dispatch('fetchAppointmentDataWithOrder');
      }

      let searchCriteria = {
        filterGroups: [
          {
            filters: [
              {
                field: 'order_id',
                value: entityId,
              },
            ],
          },
        ],
      };
      let response = await DataService.get(`${activeStoreView.code}/shipments`, {
        searchCriteria,
      });
      if (response.data.status === 'success') {
        commit('fetchShipmentsSuccess', response.data.data);
      }

      response = await DataService.get(`${activeStoreView.code}/invoices`, {
        searchCriteria,
      });
      if (response.data.status === 'success') {
        commit('fetchInvoicesSuccess', response.data.data);
      }

      response = await DataService.get(`${activeStoreView.code}/refunds`, {
        searchCriteria,
      });
      if (response.data.status === 'success') {
        commit('fetchRefundsSuccess', response.data.data);
        commit('fetchEnd');
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async createOrder({ commit, rootState, dispatch }, payload) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      const user = rootState.auth.user;
      commit('startSaving');

      let {
        data: { data, status },
      } = await DataService.post(`${activeStoreView.code}/orders`, {
        entity: payload,
      });
      if (status === 'success') {
        const jwtSecretKey = process.env[`VUE_APP_SECRET_${user.client_id}`];
        const token = jwt.sign(
          {
            orderId: data.entity_id,
            email: payload.customer_email,
            protected_code: data.protect_code,
            value: data.total_due,
            activeStoreView: activeStoreView.code,
          },
          jwtSecretKey,
          {
            expiresIn: '20d',
          },
        );

        let newPayload = {
          ...payload,
          entity_id: data.entity_id,
          items: [],
        };
        newPayload.extension_attributes.vehicle_order = token;
        const vsfOrderUrl = activeStoreView.vsf_url + '/my-order/' + token;

        let {
          data: { status: updatedStatus },
        } = await DataService.post(`${activeStoreView.code}/orders`, {
          entity: newPayload,
          vsf_url: vsfOrderUrl,
        });

        if (updatedStatus === 'success') {
          dispatch('fetchOrders');
          commit('endSaving');
          Notification['success']({
            message: 'Created order successfully.',
          });
        }
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async createShipmentTrack({ state, commit, rootState }, { form, orderId }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('startSaving');
      const entity = {
        order_id: orderId,
        description: '',
        parent_id: state.order.entity_id,
        qty: 1, //default
        carrier_code: form.carrier,
        title: form.title,
        track_number: form.number,
        weight: 1, //default
      };
      let {
        data: { data, status },
      } = await DataService.post(`${activeStoreView.code}/shipments/track`, {
        entity,
      });
      if (status === 'success') {
        commit('createShipmentTrackSuccess', data);
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async deleteShipmentTrack({ commit, rootState }, trackId) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('startSaving');
      let {
        data: { status },
      } = await DataService.delete(`${activeStoreView.code}/shipments/track/${trackId}`);
      if (status === 'success') {
        commit('endSaving');
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async createShipment({ commit, rootState, dispatch }, { payload, orderId }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('startSaving');
      let { data } = await DataService.post(`${activeStoreView.code}/orders/${orderId}/ship`, payload);
      if (data.status === 'success') {
        const shipmentId = data.data;
        await dispatch('fetchOrder', {
          entityId: orderId,
          flag: false,
        });
        router.push({ name: 'omni-order-shipments', params: { shipmentId: shipmentId, id: orderId } });
        commit('endSaving');
      }
    } catch ({ data }) {
      commit('setError', data.error);
    }
  },

  async createInvoice({ state, commit, rootState, dispatch }, { payload, orderId, totalPrice }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('startSaving');
      const paymentIntent = state?.order?.extension_attributes?.payment_additional_info.find(
        (info) => info.key === 'paymentID',
      );

      // const paymentProvider = rootState.auth?.user?.store_views[0].payment_provider;
      const paymentProvider = state.order?.payment?.method;
      // const totalAmount = payload?.entity?.items.reduce((result, item) => {
      //   result += Number(item.qty);
      //   return result;
      // }, 0);
      const searchCriteria = {
        filterGroups: [
          {
            filters: [
              {
                field: 'order_id',
                value: orderId,
              },
            ],
          },
        ],
      };

      if (paymentIntent && paymentIntent.value) {
        let thirdPartyPaymentRes = null;
        if (paymentProvider === 'stripepayment') {
          // Stripe must have the value *100
          let formatedAmount = totalPrice * 100;
          thirdPartyPaymentRes = await DataService.post(`${activeStoreView.code}/stripe/capture`, {
            amount: Math.round(formatedAmount),
            payment_id: paymentIntent.value,
          });
        } else if (paymentProvider === 'barclayspayment') {
          // const orderSubTotal = state?.order?.subtotal;
          thirdPartyPaymentRes = await DataService.post(`${activeStoreView.code}/barclays/capture`, {
            transaction_no: '0001',
            transaction_reference: paymentIntent.value,
          });
        }
        if (thirdPartyPaymentRes.data.status === 'success') {
          const response = await DataService.get(`${activeStoreView.code}/invoices`, {
            searchCriteria,
          });
          if (!response.data.items.length) {
            payload.totalPrice += state.order.shipping_amount;
          }

          let {
            data: { status },
          } = await DataService.post(`${activeStoreView.code}/orders/${orderId}/invoice`, payload);
          if (status === 'success') {
            const response = await DataService.get(`${activeStoreView.code}/invoices`, {
              searchCriteria,
            });
            if (response.data.status === 'success') {
              commit('fetchInvoicesSuccess', response.data.data);
              commit('endSaving');
              await dispatch('fetchOrder', {
                entityId: orderId,
                flag: false,
              });
              router.push({ name: 'omni-order-invoices' });
            }
          }
        }
      } else {
        Notification['error']({
          message: `${paymentProvider} capture failed`,
          description: 'No payment ID',
        });
        commit('endSaving');
      }
    } catch (error) {
      // console.log(error);
      commit('setError', error);
      // router.push({ name: 'omni-order-invoices' });
    }
  },

  async createInvoiceOnly({ state, commit, rootState, dispatch }, { payload, orderId, totalPrice }) {
    try {
      console.log(totalPrice, 'totalPrice');
      const activeStoreView = rootState.auth.activeStoreView;
      commit('startSaving');
      // const totalAmount = payload?.entity?.items.reduce((result, item) => {
      //   result += Number(item.qty);
      //   return result;
      // }, 0);
      const searchCriteria = {
        filterGroups: [
          {
            filters: [
              {
                field: 'order_id',
                value: orderId,
              },
            ],
          },
        ],
      };

      const response = await DataService.get(`${activeStoreView.code}/invoices`, {
        searchCriteria,
      });
      console.log(response, 'res');
      if (response?.data?.items?.length === 0) {
        payload.totalPrice += state.order.shipping_amount;
      }

      let {
        data: { status },
      } = await DataService.post(`${activeStoreView.code}/orders/${orderId}/invoice`, payload);
      if (status === 'success') {
        const response = await DataService.get(`${activeStoreView.code}/invoices`, {
          searchCriteria,
        });
        if (response.data.status === 'success') {
          commit('fetchInvoicesSuccess', response.data.data);
          commit('endSaving');
          await dispatch('fetchOrder', {
            entityId: orderId,
            flag: false,
          });
          router.push({ name: 'omni-order-invoices' });
        }
      }
    } catch (error) {
      // console.log(error);
      commit('setError', error);
      // router.push({ name: 'omni-order-invoices' });
    }
  },

  async refundOrder({ state, commit, rootState }, { payload, orderId, grandTotal }) {
    try {
      const activeStoreView = rootState.auth.activeStoreView;
      commit('fetchBegin');

      const paymentIntentId = state?.order?.payment?.additional_information[0];
      const totalAmount = Object.values(payload.arguments).reduce((sum, val) => {
        sum += val;
        return sum;
      }, 0);
      const orderSubTotal = state?.order?.subtotal;
      console.log(totalAmount, orderSubTotal, grandTotal);

      if (paymentIntentId) {
        // const paymentProvider = rootState.auth?.user?.store_views[0].payment_provider;

        // let thirdPartyPaymentRes = null;
        // if (paymentProvider === 'stripe') {
        //   thirdPartyPaymentRes = await DataService.post(`${activeStoreView.code}/stripe/refund`, {
        //     amount_to_capture: totalAmount,
        //     payment_id: paymentIntentId,
        //   });
        // } else if (paymentProvider === 'barclaycard') {
        //   thirdPartyPaymentRes = await DataService.post(`${activeStoreView.code}/barclaycard/refund`, {
        //     amount_to_capture: +grandTotal,
        //     payment_id: paymentIntentId,
        //     is_full_amount: orderSubTotal === +grandTotal,
        //   });
        // }

        // if (thirdPartyPaymentRes.data.status === 'success') {
        let {
          data: { status },
        } = await DataService.post(`${activeStoreView.code}/orders/${orderId}/refund`, payload);
        if (status === 'success') {
          Notification['success']({
            message: 'Create credit memo success',
            description: 'You created the credit memo.',
          });
          commit('fetchEnd');
        }
        // }
      }
    } catch ({ data }) {
      commit('setError', data.error);
      router.push({ name: 'omni-order-invoices' });
    }
  },

  async checkPickup({ rootState }, payload) {
    const activeStoreView = rootState.auth.activeStoreView;
    let {
      data: { status, data },
    } = await DataService.post(`${activeStoreView.code}/orders/notify-orders-are-ready-for-pickup`, payload);
    if (status === 'success') {
      if (data.successful) {
        Notification['success']({
          message: 'Possible to pick up',
        });
      } else {
        Notification['error']({
          message: 'not_read_for_pickup',
          description: data.errors,
        });
      }
    }
  },

  async createOrderComment({ rootState, commit }, { entityId, payload }) {
    const activeStoreView = rootState.auth.activeStoreView;

    commit('startSaving');
    try {
      const {
        data: { status },
      } = await DataService.post(`${activeStoreView.code}/orders/${entityId}/comments`, payload);
      if (status === 'success') {
        let {
          data: { data, status },
        } = await DataService.get(`${activeStoreView.code}/orders/${entityId}`);
        if (status === 'success') {
          commit('fetchOrderSuccess', data);
        }
      }
    } catch (error) {
      commit('endSaving');
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
