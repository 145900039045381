export default {
  setDateRange(state, value) {
    state.formState.startDate = value[0];
    state.formState.endDate = value[1];
  },
  setReportData(state, value) {
    state.reportData = value;
  },
  setReportFilter(state, value) {
    state.formState = value;
  },
  setStoreviewsForReport(state, value) {
    state.storeviews = value;
  },
  setReportClientId(state, value) {
    state.formState.clientId = value;
  },
  setReportError(state, err) {
    state.error = err;
  },
  setReportingData(state, value) {
    state.reportingData = value;
  },
  setOrderNumbers(state, value) {
    state.orderNumbers = value;
  },
  setEnquiryNumbers(state, value) {
    state.enquiryNumbers = value;
  },
};
