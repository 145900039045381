const initialAppointment = {
  id: -1,
  start_time: '',
  end_time: null,
  orderid: null,
  booked_online: '',
  slot_id: '',
  note: 0,
  internal_booking: '',
  technical_id: '',
};

const initialAppointmentData = {
  id: -1,
  customer: '',
  start_time: '',
  end_time: null,
  order_id: null,
  booked_online: '',
  slot_id: '',
  slot_ids: [],
  note: '',
  internal_booking: 1,
  technician_id: [],
};

export default {
  setModalLoading(state, value) {
    state.setModalLoading = value;
  },
  setAppointmentOrder(state, value) {
    state.appointmentData.order_id = value;
  },
  setAppointmentCustomer(state, customer) {
    state.appointmentData.customer = customer;
  },
  setHasOrder(state, value) {
    state.hasOrder = value;
  },
  setWeeks(state, value) {
    state.weeks = value;
  },
  setIsEdit(state, value) {
    state.isEdit = value;
    if (value) state.appointmentData.isEdit = true;
  },
  initSlotIds(state) {
    state.slot_ids = [];
  },
  addSlotID(state, id) {
    if (state.isEdit) state.slot_ids = [id];
    else {
      let data = state.slot_ids?.filter((slot_id) => !!slot_id);
      console.log(data, 'data');
      data.push(id);
      state.slot_ids = data;
    }
  },
  removeSlotID(state, id) {
    state.slot_ids = state.slot_ids.filter((slot_id) => slot_id !== id);
  },
  setAppointmentFilters(state, data) {
    Object.assign(state.filters, data);
  },
  setSlotID(state, id) {
    state.slot_id = id;
  },
  async fetchAppointmentDataSuccess(state, data) {
    state.appointment = data;
    state.slot_ids = [data.slot_id];
    let payload = {
      id: data.id,
      customer: data.customer,
      start_time: data.start_time,
      end_time: data.end_time,
      order_id: data.order_id,
      booked_online: data.booked_online,
      note: data.note,
      internal_booking: data.internal_booking,
      technician_id: [data.technician_id],
    };
    state.appointmentData = payload;
    state.loading = false;
  },
  fetchSlotDataDataSuccess(state, data) {
    state.slotData = [...data];
  },
  fetchTechniciansDataSuccess(state, data) {
    state.technicianData = [...data];
  },
  setCurrentDay(state, date) {
    state.currentDate = date;
  },
  fetchAppointmentsBegin(state) {
    state.loading = true;
  },
  fetchAppointmentBegin(state) {
    state.modalLoading = true;
  },
  fetchAppointmentsSuccess(state, data) {
    state.appointments = data;
    state.modalLoading = false;
  },
  fetchAppointmentsDataSuccess(state, { data, current_page, total, per_page }) {
    state.appointmentsData = data;
    state.pagination.currentPage = current_page;
    state.pagination.total = total;
    state.pagination.perPage = per_page;
    state.loading = false;
  },
  initAppointmentData(state) {
    state.appointmentData = { ...initialAppointmentData };
    state.slot_ids = [];
  },
  // fetchEnquirySuccess(state, enquiry) {
  //   // TODO: should get object directly
  //   state.enquiry = enquiry[0];
  //   state.loading = false;
  // },
  updatePagination(state, { key, value }) {
    state.pagination[key] = value;
  },
  initAppointment(state) {
    state.appointment = initialAppointment;
  },
  // storeviewsSavingBegin(state) {
  //   state.saving = true;
  // },
  // storeviewsSavingEnd(state) {
  //   state.saving = false;
  //   state.loading = false;
  // },
  setError(state, err) {
    if (err) state.error = 'There was an error processing this request';
    state.loading = false;
    state.saving = false;
  },
  // setEnquiriesSearchEmail(state, key) {
  //   state.searchKey = key;
  // },
};
